@import '../../styles/variables';
@import '../../styles/mixins';

.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 40px;
  box-shadow: 0 4px 8px -2px #0000001a;
  background-color: #fff;
  z-index: 5;

  .headerInner {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;

    .headerLogo {
      background-color: #fff;
      margin-bottom: -30px;
      box-shadow: 0 6px 12px -2px #0000001a;
      border-radius: 0 0 2px 2px;
      padding: 20px;
    }
  }

  .headerNavigationItems {
    width: 70%;
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: flex-end;
    padding-right: 50px;
    padding-left: 0;

    p {
      cursor: pointer;
      font-weight: 600;
    }
  }

  .hamburgerNavigation {
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 1000;
    border-bottom-left-radius: 20px;
    transition: right 0.5s;
    width: 265px;
    padding: 2rem 1rem 1rem;
    display: block;
    right: -300px;
    box-shadow: none;

    &.isOpen {
      right: 0px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px 100vmax;
    }

    .hamburgerIcons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .hamburgerItems {
      p {
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  gap: 20px;
  background-color: #fcf000;
  padding: 40px;

  @include bp(m) {
    gap: 100px;
    align-items: center;
    flex-direction: row;
  }

  .imageContainer {
    width: 320px;
    order: 2;

    @include bp(m) {
      order: 1;
      width: 520px;
    }

    .image {
      width: 100%;
      height: 100%;
    }
  }

  .textContainer {
    order: 1;
    max-width: 700px;

    @include bp(m) {
      order: 2;
      width: 700px;
    }

    .copyrightText {
      font-weight: bold;
    }

    .dataPrivacyStatement {
      font-weight: bold;
      color: #959ea0;
      font-size: 14px;
      margin: 4px;
      padding: 0;
    }

    p {
      margin: 4px;
      padding: 0;
      font-size: 14px;
    }
  }
}
