@import "../../styles/variables";

.button {
  height: 48px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);

  &:global(.MuiButton-containedPrimary) {
    background-color: $primary-button-background;
    box-shadow: $primary-button-shadow;
    border-radius: 8px;
    padding: 11px 40px;
    font-family: $jost;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    height: 50px;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: rgba(145, 158, 171, 0.24);
    }

    &:hover {
      background-color: $primary-button-background-hover;
    }
  }

  &:global(.MuiButton-containedSecondary) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 32px;
    width: fit-content;
    height: 42px;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    border-radius: 40px;
    box-shadow: none;
    color: $secondary-button-text-color;
    background-color: $secondary-button-color;
    text-transform: capitalize;

    &:hover {
      background-color: $secondary-button-color-hover;
      box-shadow: none;
    }

    &:focus {
      outline: 2px solid $secondary-button-color-focus;
    }

    &:global(.Mui-disabled) {
      background-color: $secondary-button-color-disabled;
    }
  }

  &:global(.MuiButton-textPrimary) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 700;
    line-height: 1.71429;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: $jost;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: $primary-button-background;
  }

  &:global(.MuiButton-containedWarning) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 700;
    line-height: 1.71429;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: $jost;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: $primary-button-background;
  }

  &:global(.MuiButton-containedSuccess) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 32px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    border-radius: 40px;
    border: none;
    background-color: $success-button-background;
    color: $color-white;
    box-shadow: none;
    text-transform: capitalize;

    &:hover {
      background-color: $purple-color;
      box-shadow: none;
    }
  }

  &:global(.MuiButton-containedInfo) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 32px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    border-radius: 40px;
    box-shadow: none;
    border: 4px solid $info-border-color;
    color: $info-button-color;
    background-color: $color-white;
    text-transform: capitalize;

    &:hover {
      background-color: $info-secondary-background;
      box-shadow: none;
    }
  }

  &:global(.MuiButton-containedError) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 32px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    border-radius: 40px;
    box-shadow: none;
    color: $info-button-color;
    background-color: $color-white;
    text-transform: capitalize;
    background-color: transparent;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
      background-color: $color-white;
      box-shadow: none;
    }
  }

  &.saveButton {
    height: auto;
    padding: 6px 48px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.textButton {
    opacity: 0.8;
  }

  &.isActive {
    opacity: 1;
  }

  &.onlyIcon {
    padding-left: 16px;
    padding-right: 16px;
    min-width: 40px;

    :global(.MuiButton-startIcon) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .loadingIcon {
    margin-right: 4px;
  }
}
