$jost: 'Jost', sans-serif;
$dmSans: 'DM Sans', sans-serif;
$josefinSans: 'Josefin Sans', sans-serif;
$font-poppins: 'Poppins', sans-serif;
$main-font-family: 'Teko', sans-serif;
$secondary-font-family: 'Raleway', sans-serif;

$main-body-color: #1b1930;
$grey-font-color: #727299;

$light-blue-gray-color: #d0d5dd;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-light: #51ea1c;
$primary-main: #1b1930;
$primary-dark: #41c314;
$primary-extra-dark: #2e8b0e;
$primary-darker: $primary-extra-dark;
$primary-darkest: $primary-extra-dark;

$color-white: #ffffff;
$color-white-darker: #eeeeee;

$primary-button-background: #1b1930;
$primary-button-background-hover: #262345;
$primary-button-shadow: rgba(28, 49, 68, 0.12) 0 4px 8px 0;

$secondary-button-color: #fcf000;
$secondary-button-color-hover: #fff693;
$secondary-button-color-disabled: #fff693;
$secondary-button-color-hover-lightest: #fffce5;
$secondary-button-color-focus: #1b1930;
$secondary-button-text-color: #292547;

$table-background: rgba(27, 25, 48, 0.02);

$assessment-button-color: #fffac7;
$assessment-button-hover: #fefbdd;

$skills-border: #a0abbb;
$skills-text: #47485e;

$color-grey: rgb(126, 136, 143);
$color-grey-light: rgb(218, 222, 227);
$color-grey-text: #d9d9d9;
$color-grey-icon: #131a29;
$color-grey-border: #ededed;
$color-orange: #e9471d;
$color-orange-darker: #ee4216;

$label: rgb(145, 158, 171);
$label-focused: $primary-main;
$label-error: #d32f2f;

$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: $primary-main;
$input-border-error: $label-error;
$input-error-text: $label-error;

$background-color: #e8e9eb;
$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #ffffff;
$black-color: #000000;

$red-color: #e13119;
$red-color-darker: #e04321;
$red-color-brighter: #efc5bd;

$warning-color: #f19a3e;
$warning-color-brighter: #f6c48d;
$warning-color-darker: #98540b;

$sidebar-color: #313638;
$navigation-color: #313638;

$success-button-background: #454cde;

$info-button-color: #292547;
$info-border-color: #fcf000;
$info-secondary-background: #fffccc;

$purple-color: #555dff;

$light-blue-color: #00c2ff;

$result-bar-background: #292d2c;
$result-bar-border: #7c8783;
$result-bar-level-background: #535a58;
$result-bar-active: #555dff;
$result-bar-label-border: #a0abbb;
$result-bar-label-color: #47485e;

$cookie-bar-border: $info-border-color;
$cookie-bar-background: #152a36;
