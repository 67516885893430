@import "../../styles/variables";
@import "../../styles/mixins";

.errorFallback {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;

  .icon {
    color: $primary-main;

    &.errorIcon {
      color: $error-icon;
    }
  }

  .errorMessage {
    font-family: $jost;
    font-weight: 500;
    font-size: 18px;
    margin-top: 16px;
  }

  .refresh {
    font-family: $jost;
    font-weight: 500;
    font-size: 16px;
    color: $primary-main;
    margin-top: 10px;
    cursor: pointer;
  }
}
