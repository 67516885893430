@import '../../styles/mixins';
@import '../../styles/variables';

.container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 0.3125rem solid $cookie-bar-border;
  background-color: $cookie-bar-background;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  gap: 2rem;
  z-index: 5;

  @include bp(ml) {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 3.125rem;
    min-height: 6.25rem;
  }

  .messageContainer {
    text-align: center;
    font-size: 1rem;
    font-family: $font-poppins;
    color: $color-white-darker;

    @include bp(ml) {
      text-align: left;
      width: 80%;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: center;
    gap: 0.625rem;
    flex-wrap: wrap;

    @include bp(ml) {
      flex-wrap: nowrap;
    }

    .rejectButton {
      color: $color-white;

      &:hover {
        background-color: unset;
      }
    }
  }
}
