@import "../../styles/variables";
@import "../../styles/mixins";

.navigationContainer {
  display: flex;
  align-items: center;
  color: $primary-main;
  padding: 20px 18px;
  right: 0;
  z-index: 8;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3px);
  justify-content: space-between;
  box-shadow: rgba(34, 51, 84, 0.2) 0 2px 8px -3px, rgba(34, 51, 84, 0.1) 0px 5px 22px -4px;
  width: 100%;

  @include bp (m) {
    height: 80px;
    left: 290px;
    padding: 0 18px;
    position: fixed;
    width: calc(100% - 290px);
  }

  .iconItems {
    display: flex;
    align-items: center;

    button {
      color: $primary-main;
    }
  }
}
